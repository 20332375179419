import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import AppContext from './AppContext';
import ChatOptionsData from './AppContext';
import { FormCheck } from 'react-bootstrap';

interface ChatOptionsFormProps {
    sendChatOptions: (data:any) => void;
}

interface ChatOptionsForm {
    state: ChatOptionsFormState;
    props: ChatOptionsFormProps;
    sendChatOptions: (data:any) => void;
    drpPriceRef: React.RefObject<HTMLSelectElement>|null;
}

interface ChatOptionsFormState {
    estatus:string;
    formstatus:string;
    welcomeMessage:string;
    vCard:string;
    vCardAnyone:string;
    allowFreeChat:boolean;
    autoEnableSound:boolean;
    offerFreeSound:boolean;
    autoSoundAccept:boolean;
    autoCam2CamAccept:boolean;
    autoDildoAccept:boolean;
    autoUserMicAccept:boolean;
    autotext1:string;
    autotext2:string;
    autotext3:string;
    autotext4:string;
    autotext5:string;
    errorMessage:string;
    noUserEnterSound:boolean;
    noUserLeaveSound:boolean;
    noChatMessageSound:boolean;
    noFeatureRequestSound:boolean;
    price: number;
    maxPrice: number;
    multiPrivatePrice: number;
    allowStaging: boolean;
    stagingMode: boolean;
}

class ChatOptionsForm extends React.Component {
    static contextType = AppContext;

    constructor(props:ChatOptionsFormProps) {
        super(props);
        this.state = {
            estatus: "",
            formstatus: "loading",
            price: 1,
            maxPrice: 2,
            multiPrivatePrice: 2,
            welcomeMessage: '',
            vCard: '',
            vCardAnyone: '',
            autoEnableSound: false,
            allowFreeChat: false,
            offerFreeSound: false,
            autoSoundAccept: false,
            autoCam2CamAccept: false,
            autoDildoAccept: false,
            autoUserMicAccept: false,
            autotext1: '',
            autotext2: '',
            autotext3: '',
            autotext4: '',
            autotext5: '',
            errorMessage: '',
            noUserEnterSound: true,
            noUserLeaveSound: true,
            noChatMessageSound: true,
            noFeatureRequestSound: true,
            allowStaging: false,
            stagingMode: false
        };
    
        this.sendChatOptions = props.sendChatOptions;

        this.drpPriceRef = React.createRef();
    }

    componentDidMount() {
        this.loadData();
    }

    removeEmojis(exp:string) {
        let regex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
        return exp.replace(regex, '');
    }

    handlePriceChange(event:React.ChangeEvent<HTMLInputElement>) {
        this.setState({price: event.target.value});
    }

    handleWelcomeMessageChange(event:React.ChangeEvent<HTMLInputElement>) {
        this.setState({welcomeMessage: this.removeEmojis(event.target.value)});
    }

    handleVCardForAdultsChange(event:React.ChangeEvent<HTMLInputElement>) {
        this.setState({vCard: this.removeEmojis(event.target.value)});
    }

    handleVCardForAnyoneChange(event:React.ChangeEvent<HTMLInputElement>) {
        this.setState({vCardAnyone: this.removeEmojis(event.target.value)});
    }

    handleStagingChange(event:React.ChangeEvent<HTMLInputElement>) {
        this.setState({stagingMode: event.target.checked});
    }

    handleAutoEnableSoundChange(event:React.ChangeEvent<HTMLInputElement>) {
        this.setState({autoEnableSound: event.target.checked});
    }

    handleAutoAcceptSoundChange(event:React.ChangeEvent<HTMLInputElement>) {
        this.setState({autoSoundAccept: event.target.checked});
    }

    handleAutoAcceptDildoChange(event:React.ChangeEvent<HTMLInputElement>) {
        this.setState({autoDildoAccept: event.target.checked});
    }

    handleAutoAcceptCam2CamChange(event:React.ChangeEvent<HTMLInputElement>) {
        this.setState({autoCam2CamAccept: event.target.checked});
    }

    handleAutoAcceptUsermicChange(event:React.ChangeEvent<HTMLInputElement>) {
        this.setState({autoUserMicAccept: event.target.checked});
    }

    handleNoUserEnterSoundChange(event:React.ChangeEvent<HTMLInputElement>) {
        this.setState({noUserEnterSound: event.target.checked});
    }

    handleNoUserLeaveSoundChange(event:React.ChangeEvent<HTMLInputElement>) {
        this.setState({noUserLeaveSound: event.target.checked});
    }

    handleNoChatMessageSoundChange(event:React.ChangeEvent<HTMLInputElement>) {
        this.setState({noChatMessageSound: event.target.checked});
    }

    handleNoFeatureRequestSoundChange(event:React.ChangeEvent<HTMLInputElement>) {
        this.setState({noFeatureRequestSound: event.target.checked});
    }

    handleAutoText1Change(event:React.ChangeEvent<HTMLInputElement>) {
        this.setState({autotext1: event.target.value});
    }

    handleAutoText2Change(event:React.ChangeEvent<HTMLInputElement>) {
        this.setState({autotext2: event.target.value});
    }

    handleAutoText3Change(event:React.ChangeEvent<HTMLInputElement>) {
        this.setState({autotext3: event.target.value});
    }

    handleAutoText4Change(event:React.ChangeEvent<HTMLInputElement>) {
        this.setState({autotext4: event.target.value});
    }

    handleAutoText5Change(event:React.ChangeEvent<HTMLInputElement>) {
        this.setState({autotext5: event.target.value});
    }

    loadData() {
        var jsonurl = "https://guppy.site/chatSetupDataGet.aspx?ProducerID=" + encodeURIComponent(this.context.loginData.producerID);
    
        this.setState({formstatus: "loading"});
    
        const axios = require('axios').default;
        const cryptoRandomString = require('crypto-random-string');
    
        axios.get(jsonurl).then((response:any) => {
            this.setState({loading: false});
            let data = response.data;

            if(data.result === "ok") {
                this.setState({formstatus: "ready"});

                this.setState({
                    price: data.price,
                    welcomeMessage: data.welcomeMessage,
                    vCard: data.vCardForAdults,
                    vCardAnyone: data.vCardForAnyone,
                    maxPrice: data.maxPrice,
                    allowFreeChat: data.allowFreeChat,
                    allowMultiPrivate: data.allowMultiPrivate,
                    maxPriceMultiPrivate: data.maxPriceMultiPrivate,
                    privatePrice: data.privatePrice,
                    autotext1: data.autotext1,
                    autotext2: data.autotext2,
                    autotext3: data.autotext3,
                    autotext4: data.autotext4,
                    autotext5: data.autotext5,
                    autoSoundAccept: data.autoSoundAccept === "True" ? true : false,
                    autoDildoAccept: data.autoDildoAccept === "True" ? true : false,
                    autoUserMicAccept: data.autoUserMicAccept === "True" ? true : false,
                    autoEnableSound: data.autoEnableSound === "True" ? true : false,
                    offerFreeSound: data.offerFreeSound === "True" ? true : false,
                    autoCam2CamAccept: data.autoCam2CamAccept === "True" ? true : false,
                    noUserEnterSound: data.noUserEnterSound,
                    noUserLeaveSound: data.noUserLeaveSound,
                    noChatMessageSound: data.noChatMessageSound,
                    noFeatureRequestSound: data.noFeatureRequestSound,
                    allowStaging: data.allowStaging
                });

                if(this.drpPriceRef !== null) {
                    if(this.drpPriceRef.current !== null) {
                        this.drpPriceRef.current.value = data.price;
                    }
                }
            } else {
                this.setState({formstatus: "error"});
                this.setState({errorMessage: "Error loading data: " + data.error});
            }
        })
        .catch((error:any) => {
          this.setState({errorMessage: error.toString()});
          this.setState({loading: false});
        });
    }

    handleSubmit(event:React.ChangeEvent<HTMLFormElement>) {
        event.preventDefault();
    
        console.log("Submit clicked");
    
        var produderid = "";
    
        var jsonurl = "https://guppy.site/chatSetupDataSave.aspx?ProducerID=" + encodeURIComponent(this.context.loginData.producerID);

        jsonurl += "&WelcomeMessage=" + encodeURIComponent(this.state.welcomeMessage);
        jsonurl += "&vCardForAdults=" + encodeURIComponent(this.state.vCard);
        jsonurl += "&vCardForAnyone=" + encodeURIComponent(this.state.vCardAnyone);
        jsonurl += "&Price=" + encodeURIComponent(this.state.price);
        jsonurl += "&MultiPrivatePrice=" + encodeURIComponent(this.state.multiPrivatePrice);

        jsonurl += "&autotext1=" + encodeURIComponent(this.state.autotext1);
        jsonurl += "&autotext2=" + encodeURIComponent(this.state.autotext2);
        jsonurl += "&autotext3=" + encodeURIComponent(this.state.autotext3);
        jsonurl += "&autotext4=" + encodeURIComponent(this.state.autotext4);
        jsonurl += "&autotext5=" + encodeURIComponent(this.state.autotext5);

        jsonurl += "&autoEnableSound=" + (this.state.autoEnableSound ? "1" : "0");
        jsonurl += "&autoSoundAccept=" + (this.state.autoSoundAccept ? "1" : "0");

        jsonurl += "&staging=" + (this.state.stagingMode ? "1" : "0");

        jsonurl += "&autoDildoAccept=" + (this.state.autoDildoAccept ? "1" : "0");
        jsonurl += "&autoUserMicAccept=" + (this.state.autoUserMicAccept ? "1" : "0");
        jsonurl += "&offerFreeSound=" + (this.state.offerFreeSound ? "1" : "0");
        jsonurl += "&autoCam2CamAccept=" + (this.state.autoCam2CamAccept ? "1" : "0");
        jsonurl += "&noUserEnterSound=" + (this.state.noUserEnterSound ? "1" : "0");
        jsonurl += "&noUserLeaveSound=" + (this.state.noUserLeaveSound ? "1" : "0");
        jsonurl += "&noChatMessageSound=" + (this.state.noChatMessageSound ? "1" : "0");
        jsonurl += "&noFeatureRequestSound=" + (this.state.noFeatureRequestSound ? "1" : "0");
    
        this.setState({loading: true});
    
        const axios = require('axios').default;
        const cryptoRandomString = require('crypto-random-string');
    
        axios.get(jsonurl).then((response:any) => {
            let data = response.data;
            console.log("login result: " + data.result + " | error: " + data.error);
            if(data.result === "ok") {
                this.sendChatOptions(data);
            } else {
                this.setState({errorMessage: data.error});
                this.setState({loading: false});
            }
        })
        .catch((error:any) => {
          this.setState({errorMessage: error.toString()});
          this.setState({loading: false});
        });
    }

    getText(exp:string) {
        if(this.context.language === "de") {
            if(exp === "AutoAccept Sound") {
                return "Sound automatisch annehmen";
            }
            if(exp === "AutoAccept Dildo/Lovense") {
                return "Dildo/Lush automatisch annehmen";
            }
            if(exp === "AutoAccept Usermic") {
                return "Usermikrofon automatisch annehmen";
            }
            if(exp === "AutoAccept Cam2Cam") {
                return "Cam-2-Cam automatisch annehmen";
            }
            if(exp === "AutoTextsHint") {
                return "Hier kann man sich Autotexte anlegen, die dann automatisch erscheinen, wenn man im Eingefeld für Chatnachrichten # und die dazugehörige Ziffer eintippt";
            }
            if(exp === "Price") {
                return "Preis";
            }
            if(exp === "Offer sound for free") {
                return "Die User können den Sound kostenlos aktivieren";
            }
            if(exp === "Sound free") {
                return "Sound kostenlos";
            }
            if(exp === "AutoEnable Sound") {
                return "Sound gleich für User anbieten";
            }
            if(exp === "WelcomeMessage") {
                return "Automatische Chatnachricht an User beim Betreten";
            }
            if(exp === "vCardForAdults") {
                return "InfoText für altersverifizierte User";
            }
            if(exp === "vCardForAnyone") {
                return "InfoText soft";
            }
            if(exp === "Submit") {
                return "Abschicken";
            }
            if(exp === "NoUserEnterSound") {
                return "Kein Hinweiston wenn User den Chat betritt";
            }
            if(exp === "NoUserLeaveSound") {
                return "Kein Hinweiston wenn User den Chat verlässt";
            }
            if(exp === "NoChatMessageSound") {
                return "Kein Hinweiston wenn User bei Chatnachricht";
            }
            if(exp === "NoFeatureRequestSound") {
                return "Kein Hinweiston wenn Feature angefragt wird";
            }
            if(exp === "ChatOptionsFormSubtitle") {
                return "Du bist als {0} eingeloggt, nimm hier Deine Einstellungen vor, mit denen Du online gehen möchtest";
            }
            if(exp === "KostenloserBasischat") {
                return "Kostenloser Basischat";
            }
        } else if(this.context.language === "en") {
            if(exp === "AutoTextsHint") {
                return "These auto-texts help you reduce your typing, you can fill in the auto-texts as confgured below by entering # and the corresponding number in the chat input textbox";
            }
            if(exp === "Willkommensnachricht im Chat") {
                return "Welcome message for users entering your room";
            }
            if(exp === "AutoEnable Sound") {
                return "Allow Users to enable Sound";
            }
            if(exp === "WelcomeMessage") {
                return "Automatic chat message to users entering chat";
            }
            if(exp === "vCardForAdults") {
                return "InfoText for age-verified users (can contain porn-language)";
            }
            if(exp === "vCardForAnyone") {
                return "InfoText for non age-verified users (soft, no porn-language)";
            }
            if(exp === "Price") {
                return "Price";
            }
            if(exp === "NoUserEnterSound") {
                return "Disable notification sound on user enter";
            }
            if(exp === "NoUserLeaveSound") {
                return "Disable notification sound on user leave";
            }
            if(exp === "NoChatMessageSound") {
                return "Disable notification sound on chat message";
            }
            if(exp === "NoFeatureRequestSound") {
                return "Disable not. sound on feature request";
            }
            if(exp === "ChatOptionsFormSubtitle") {
                return "You are logged in as {0}, here you can set options and settings for your chat session";
            }
            if(exp === "KostenloserBasischat") {
                return "Free chat base functionality";
            }
        }
    
        return exp;
    }

    render() {
        return (
            <div id='chatOptionsFormDIV' className='container pt-4'>
                <div className="mb-2"><small>{this.getText("ChatOptionsFormSubtitle").replace("{0}",this.context.loginData.pseudo)}</small></div>

                <Alert variant="danger" show={this.state.estatus != ''}>
                {this.state.estatus}
                </Alert>

                <Form onSubmit={this.handleSubmit.bind(this)}>
                    <div className="row">
                        <div className="col-6">
                            <Form.Group>
                                <Form.Label>{this.getText("Price")}</Form.Label>
                                <Form.Control id="drpPrice" ref={this.drpPriceRef} as="select" onChange={this.handlePriceChange.bind(this)}>
                                    { this.state.allowFreeChat ? <option value="0">{this.getText("KostenloserBasischat")}</option> : null }
                                    <option value="1">1 Coin/min</option>
                                    {
                                        this.state.maxPrice > 1 &&
                                        Array.from({ length: this.state.maxPrice - 1 }, (_, index) => index + 2).map(i => (
                                            <option key={i} value={i}>
                                                {i} Coins/min
                                            </option>
                                        ))
                                    }
                                </Form.Control>
                            </Form.Group>
                        </div>
                        <div className="col-6">
                            <Form.Group>
                                <Form.Check type="checkbox" label={this.getText("AutoEnable Sound")} checked={this.state.autoEnableSound} onChange={this.handleAutoEnableSoundChange.bind(this)} />
                            </Form.Group>
                        </div>
                    </div>
                    <Form.Group>
                        <Form.Label>{this.getText("WelcomeMessage")}</Form.Label>
                        <Form.Control type="text" className="avoidEmoji" placeholder={this.getText("WelcomeMessage")} value={this.state.welcomeMessage} onChange={this.handleWelcomeMessageChange.bind(this)} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{this.getText("vCardForAdults")}</Form.Label>
                        <Form.Control type="text" className="avoidEmoji" placeholder={this.getText("vCardForAdults")} value={this.state.vCard} onChange={this.handleVCardForAdultsChange.bind(this)} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{this.getText("vCardForAnyone")}</Form.Label>
                        <Form.Control type="text" className="avoidEmoji" placeholder={this.getText("vCardForAnyone")} value={this.state.vCardAnyone} onChange={this.handleVCardForAnyoneChange.bind(this)} />
                    </Form.Group>

                    { this.state.allowStaging ?
                    <div className="row">
                        <div className="col-6">
                        <Form.Group>
                            <Form.Check type="checkbox" label={this.getText("Staging")} checked={this.state.stagingMode} onChange={this.handleStagingChange.bind(this)} />
                        </Form.Group>
                        </div>
                    </div> : null }

                    <div className="row">
                        <div className="col-6">
                        <Form.Group>
                            <Form.Check type="checkbox" label={this.getText("AutoAccept Sound")} checked={this.state.autoSoundAccept} onChange={this.handleAutoAcceptSoundChange.bind(this)} />
                        </Form.Group>
                        </div>
                        <div className="col-6">
                            <Form.Group>
                                <Form.Check type="checkbox" label={this.getText("AutoAccept Dildo/Lovense")} checked={this.state.autoDildoAccept} onChange={this.handleAutoAcceptDildoChange.bind(this)} />
                            </Form.Group>  
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6">
                            <Form.Group>
                                <Form.Check type="checkbox" label={this.getText("AutoAccept Cam2Cam")} checked={this.state.autoCam2CamAccept} onChange={this.handleAutoAcceptCam2CamChange.bind(this)} />
                            </Form.Group>
                        </div>
                        <div className="col-6">
                            <Form.Group>
                                <Form.Check type="checkbox" label={this.getText("AutoAccept UserMic")} checked={this.state.autoUserMicAccept} onChange={this.handleAutoAcceptUsermicChange.bind(this)} />
                            </Form.Group>
                        </div>
                    </div>

                    <hr></hr>

                    <div className="row">
                        <div className="col-6">
                            <Form.Group>
                                <Form.Check type="checkbox" label={this.getText("NoUserEnterSound")} checked={this.state.noUserEnterSound} onChange={this.handleNoUserEnterSoundChange.bind(this)} />
                            </Form.Group>
                        </div>
                        <div className="col-6">
                            <Form.Group>
                                <Form.Check type="checkbox" label={this.getText("NoUserLeaveSound")} checked={this.state.noUserLeaveSound} onChange={this.handleNoUserLeaveSoundChange.bind(this)} />
                            </Form.Group>  
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <Form.Group>
                                <Form.Check type="checkbox" label={this.getText("NoChatMessageSound")} checked={this.state.noChatMessageSound} onChange={this.handleNoChatMessageSoundChange.bind(this)} />
                            </Form.Group>
                        </div>
                        <div className="col-6">
                            <Form.Group>
                                <Form.Check type="checkbox" label={this.getText("NoFeatureRequestSound")} checked={this.state.noFeatureRequestSound} onChange={this.handleNoFeatureRequestSoundChange.bind(this)} />
                            </Form.Group>
                        </div>
                    </div>

                    <Button variant="primary" type="submit" disabled={ this.state.formstatus === "ready" ? false : true }>{this.getText("Submit")}</Button>

                    <hr></hr>

                    <Form.Group>
                        <Form.Label>{this.getText("Autotext1")}</Form.Label>
                        <Form.Control type="text" placeholder={this.getText("Autotext1")} value={this.state.autotext1} onChange={this.handleAutoText1Change.bind(this)} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{this.getText("Autotext2")}</Form.Label>
                        <Form.Control type="text" placeholder={this.getText("Autotext2")} value={this.state.autotext2} onChange={this.handleAutoText2Change.bind(this)} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{this.getText("Autotext3")}</Form.Label>
                        <Form.Control type="text" placeholder={this.getText("Autotext3")} value={this.state.autotext3} onChange={this.handleAutoText3Change.bind(this)} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{this.getText("Autotext4")}</Form.Label>
                        <Form.Control type="text" placeholder={this.getText("Autotext4")} value={this.state.autotext4} onChange={this.handleAutoText4Change.bind(this)} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{this.getText("Autotext5")}</Form.Label>
                        <Form.Control type="text" placeholder={this.getText("Autotext5")} value={this.state.autotext5} onChange={this.handleAutoText5Change.bind(this)} />
                    </Form.Group>

                    <p>&nbsp;</p>
                </Form>
            </div>
        )
    }
}

export default ChatOptionsForm;