import React, { FormEvent } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import { LocalStorageWorker } from './StorageHelper';
import { CryptoHelper } from './CryptoHelper.js';
import AppContext from './AppContext';
import PropTypes from 'prop-types';
import { withRouter } from "react-router";
import { RouteComponentProps } from 'react-router';
import { propTypes } from 'react-bootstrap/esm/Image';

let globalLoginForm:LoginForm;

interface MatchParams {
  name: string;
}

export interface LoginFormProps extends RouteComponentProps<MatchParams> {
  sendLoginResult: (loginResult:any) => void;
  changeLanguage: (newLang:string) => void;
  match: any,
  location: any,
  history: any
}

interface LoginForm {
  state: LoginFormState;
  props: LoginFormProps;
  sendLoginResult: (loginResult:any) => void;
  changeLanguage: (newLang:string) => void;
  pToken:string;
  pPseudo:string;
  pLang:string;
}

interface LoginFormState {
  userName:string;
  password:string;
  submitting:boolean;
  saveData:boolean;
  errorMessage:string;
  language:string;
}

class LoginForm extends React.Component {
  static contextType = AppContext;

  constructor(props:LoginFormProps) {
    super(props);

    let myLocalStorage = new LocalStorageWorker();

    let defaultLanguage = "auto";
    let saveData = "1";

    if(myLocalStorage.get("language") != null) {
      defaultLanguage = myLocalStorage.get("language");
    }
    if(myLocalStorage.get("saveData") != null) {
      saveData = myLocalStorage.get("saveData");
    }

    this.state = {userName: '',password: '',submitting: false,saveData: saveData === "0" ? false : true,errorMessage: '',language: defaultLanguage};
    
    this.sendLoginResult = this.props.sendLoginResult;
    this.changeLanguage = this.props.changeLanguage;
    this.pToken = "";

    globalLoginForm = this;
  }

  componentDidMount() {
    const { match, location, history } = this.props;

    let myLocalStorage = new LocalStorageWorker();

    if(myLocalStorage.get("pseudo") != null) {
      this.setState({userName: myLocalStorage.get("pseudo")});
    }

    if(myLocalStorage.get("passwordtransit") !== null) {
      var transitmessage = myLocalStorage.get("passwordtransit").trim();
      let myCryptoHelper:CryptoHelper = new CryptoHelper();
      this.setState({password: myCryptoHelper.getDecryptedString(transitmessage)});
    }

    let myurl = location.pathname.split("/");

    if(myurl.length === 5) {
      if(myurl[1] === "login") {
        this.pPseudo = myurl[2];
        this.setState({userName: this.pPseudo});
        console.log("received pPseudo: " + this.pPseudo);
        this.pLang = myurl[3];
        this.setState({language: this.pLang});
        this.changeLanguage(this.pLang);
        console.log("received pLang: " + this.pLang);
        this.pToken = myurl[4];
        console.log("received pToken: " + this.pToken);
        this.formSubmitted();
      }
    }

    if(myLocalStorage.get("language") != null) {
      setTimeout(this.updateLanguageFromLocalStorage,500);
    }
  }

  updateLanguageFromLocalStorage() {
    let myLocalStorage = new LocalStorageWorker();
    if(myLocalStorage.get("language") != null) {
      globalLoginForm.changeLanguage(myLocalStorage.get("language"));
    }
  }

  handleUserNameChange(event:React.ChangeEvent<HTMLInputElement>) {
    this.setState({userName: event.target.value});
  }

  handlePasswordChange(event:React.ChangeEvent<HTMLInputElement>) {
    this.setState({password: event.target.value});
  }

  handleSaveDataChange(event:React.ChangeEvent<HTMLInputElement>) {
    this.setState({saveData: event.target.checked});
    let myLocalStorage = new LocalStorageWorker();
    if(event.target.checked) {
      myLocalStorage.add("saveData","1");
    } else {
      myLocalStorage.add("saveData","0");
    }
  }

  handleLanguageChange(event:React.ChangeEvent<HTMLInputElement>) {
    let newLang = event.target.value;
    this.setState({language: newLang});
    this.changeLanguage(newLang);
    let myLocalStorage = new LocalStorageWorker();
    myLocalStorage.add("language",newLang);
  }

  handleSubmit(event:FormEvent) {
    event.preventDefault();
    this.formSubmitted();
  }

  formSubmitted() {
    let myLocalStorage = new LocalStorageWorker();

    var pseudo = this.state.userName;
    var mypassword = this.state.password;
    
    if(pseudo === "") {
      if(this.pPseudo != "") {
        pseudo = this.pPseudo;
      }
    }

    var jsonurl = "https://guppy.site/SenderLogin.aspx?Pseudo=" + encodeURIComponent(pseudo) + "&Password=" + encodeURIComponent(mypassword) + "&Token=" + encodeURIComponent(this.pToken);

    this.setState({submitting: true});
    
    const axios = require('axios').default;
    const cryptoRandomString = require('crypto-random-string');

    axios.get(jsonurl).then((response:any) => {
      let data = response.data;
      console.log("login result: " + data.result + " | error: " + data.error);
      if(data.result === "ok") {
        this.setState({submitting: true});

        try {
          if(this.state.saveData) {
            myLocalStorage.add("pseudo",pseudo);
            let myCryptoHelper:CryptoHelper = new CryptoHelper();
            myLocalStorage.add("passwordtransit",myCryptoHelper.getEncryptedString(mypassword));
          } else {
            myLocalStorage.remove("pseudo");
            myLocalStorage.remove("producerid");
            myLocalStorage.remove("password");
          }
        } catch(e:any) {
          this.setState({errorMessage: "error saving encrypted pwd: " + e.toString()});
        }

        var loginData = data;

        this.sendLoginResult(loginData);
      } else {
        this.setState({errorMessage: data.error});
        this.setState({submitting: false});
      }
    })
    .catch((error:any) => {
      this.setState({errorMessage: error.toString()});
      this.setState({submitting: false});
    })
  }

  getTextLogin(exp:string) {
    if(this.context.language === "de") {
      if(exp === "Username") {
        return "Pseudo";
      }
      if(exp === "Password") {
        return "Passwort";
      }
      if(exp === "UsernameHelp") {
        return "Das Pseudo bzw. der Name mit dem Dein Livecam-Profil angezeigt wird";
      }
      if(exp === "LoginFormSubtitle") {
        return "Logge Dich hier mit Deinen Zugangsdaten ein";
      }
      if(exp === "Save Data") {
        return "Logindaten merken";
      }
      if(exp === "Submit") {
        return "Abschicken";
      }
    } else {
      if(exp === "Username") {
        return "Livecam Profile";
      }
      if(exp === "UsernameHelp") {
        return "This is the name that has been assigned to your livecam profile";
      }
      if(exp === "LoginFormSubtitle") {
        return "Please login with your livecam account login information";
      }
    }

    return exp;
  }

  render() {
    return (
      <div id='loginFormDIV' className='container pt-4'>
        <h4 className="mb-4">Sendertool Login</h4>
        <div className="mb-4"><small>{this.getTextLogin("LoginFormSubtitle")}</small></div>

        <Alert variant="danger" show={this.state.errorMessage != ''}>
          {this.state.errorMessage}
        </Alert>

        <Form onSubmit={this.handleSubmit.bind(this)}>
          <Form.Group controlId="formBasicUsername">
            <Form.Label>{this.getTextLogin("Username")}</Form.Label>
            <Form.Control type="text" placeholder={this.getTextLogin("Username")} autoFocus={this.state.userName === "" ? true : false} value={this.state.userName} onChange={this.handleUserNameChange.bind(this)} />
            <Form.Text className="text-muted">
              {this.getTextLogin("UsernameHelp")}
            </Form.Text>
          </Form.Group>

          <Form.Group controlId="formBasicPassword">
            <Form.Label>{this.getTextLogin("Password")}</Form.Label>
            <Form.Control type="password" placeholder={this.getTextLogin("Password")} autoFocus={this.state.userName !== "" && this.state.password === "" ? true : false} value={this.state.password} onChange={this.handlePasswordChange.bind(this)} />
          </Form.Group>
          <Form.Group controlId="formBasicCheckbox">
            <Form.Check type="checkbox" checked={this.state.saveData} value="1" onChange={this.handleSaveDataChange.bind(this)} label={this.getTextLogin("Save Data")} />
          </Form.Group>
          <Button variant="primary" disabled={this.state.submitting} type="submit">{this.getTextLogin("Submit")}</Button>
          <hr></hr>
          <Form.Group>
              <Form.Label>{this.getTextLogin("Language")}</Form.Label>
              <Form.Control defaultValue={this.state.language} id="drpLanguage" as="select" onChange={this.handleLanguageChange.bind(this)}>
                  <option value="en">English</option>
                  <option value="de">Deutsch/German</option>
              </Form.Control>
          </Form.Group>
        </Form>
      </div>
    );
  }
}

const LoginFormWithRouter = withRouter(LoginForm);

export default LoginFormWithRouter;